<div *ngIf="innerWidth > 500" class="mobile-container">
<div class="all-wrapper">
  <div class="wrapper wrapper-gray wrapper-top">
    <div class="wrapped-text-left wrapped-text">
      <h4><span class="dash-color">-</span>Pourquoi Courtage Patrimoine?</h4>
      <p>Choisir des professionnels maîtrisant parfaitement l'immobilier et le financement est la garantie de réaliser vos projets avec les meilleurs coûts. </p>
    </div>
    <img src="/assets/img/visuel17.png" alt="passants dans la rue">
  </div>
  <div class="wrapper wrapper-blue">
    <img src="/assets/img/visuel15.png" alt="homme se regardant dans le mirroir">
    <div class="wrapped-text-right wrapped-text last-wrapped">
      <h4>Notre vision <span class="dash-color">-</span></h4>
      <p>Courtage patrimoine a été fondée par Glenn Gendre afin de reconstruire l’approche patrimoniale et financière déjà existante. Véritables indépendants, nous ne vendons pas les produits d'autrui (promoteurs immobilier, etc..) mais créons avec vous de vrais projets.</p>
      <p>En supprimant les conflits d’intérêt, nous remplissons notre première mission : vous rendre service. Notre ambition est de simplifier vos démarches financières en vous apportant le savoir-faire et savoir-être que vous méritez pour économiser de l'argent, créer du patrimoine et générer des revenus.</p>
    </div>
  </div>
  <div>
  </div>
  <div class="wrapper wrapper-gray">
    <div class="wrapped-text-left wrapped-text">
      <h4><span class="dash-color">-</span> Rendre l'achat immobilier accessible</h4>
      <p>Par notre expertise dans le crédit immobilier, nous vous trouverons les meilleurs taux pour vos projets.</p>
      <p>Nulle nécessité d'être fortuné.e pour accéder à la propriété, contactez nous afin de déterminer ensemble les possibilités qui s’offrent à vous.</p>
    </div>
    <img src="/assets/img/visuel14.png" alt="personne se tenant a coté d'une maison">
  </div>
  <div class="wrapper wrapper-blue wrapper-bottom">
    <img src="/assets/img/visuel16.png" alt="personne adossée à un arbre">
    <div class="wrapped-text-right wrapped-text last-wrapped">
      <h4>Une équipe qualifiée à votre service <span class="dash-color">-</span></h4>
      <div>
        <p>Afin de vous apporter compétence et excellence, nous sommes :</p>
        <p>- Inscrits à l'ORIAS sous le numéro 20004733</p>
        <p>- Certifiés AMF</p>
        <p>- Formés régulièrement sur les approches innovantes de l’investissement immobilier</p>
        <p>Les courtiers auxquelles vous allez vous adresser chez Courtage Patrimoine sont déjà tous multi-propriétaires immobilier. Nous souhaitons partager avec vous cette expérience solide du terrain et de la négociation, que ce soit pour un projet d'achat de résidence principale en vous aidant à trouver votre financement ou pour réaliser un investissement immobilier locatif de A à Z.</p>
      </div>
    </div>
  </div>
</div>
</div>
<div *ngIf="innerWidth <= 500" class="mobile-container">
    <div class="mobile-intro">
    <h3>Pourquoi Courtage Patrimoine?</h3>
    <p>Choisir des professionnels maîtrisant parfaitement l'immobilier et le financement est la garantie de réaliser vos projets avec les meilleurs coûts. </p>
    </div>
  <div class="mobile-div">
    <img src="/assets/img/visuel17.png" alt="passants dans la rue" class="mobile-img">
  </div>
  <div class="aligned grey-background">
    <h4>Notre vision</h4>
      <p>Courtage patrimoine a été fondée par Glenn Gendre afin de reconstruire l’approche patrimoniale et financière déjà existante. Véritables indépendants, nous ne vendons pas les produits d'autrui (promoteurs immobilier, etc..) mais créons avec vous de vrais projets.</p>
      <p>En supprimant les conflits d’intérêt, nous remplissons notre première mission : vous rendre service. Notre ambition est de simplifier vos démarches financières en vous apportant le savoir-faire et savoir-être que vous méritez pour économiser de l'argent, créer du patrimoine et générer des revenus.</p>
  </div>
  <div class="mobile-div grey-background">
    <img src="/assets/img/visuel15.png" alt="homme se regardant dans le mirroir" class="mobile-img">
  </div>
  <div class="aligned">
    <h4>Rendre l'achat immobilier accessible</h4>
      <p>Par notre expertise dans le crédit immobilier, nous vous trouverons les meilleurs taux pour vos projets.</p>
      <p>Nulle nécessité d'être fortuné.e pour accéder à la propriété, contactez nous afin de déterminer ensemble les possibilités qui s’offrent à vous.</p>
    </div>
  <div class="mobile-div">
    <img src="/assets/img/visuel14.png" alt="personne se tenant a coté d'une maison" class="mobile-img">
  </div>
  <div class="aligned grey-background">
    <h4>Une équipe qualifiée à votre service</h4>
        <p>Afin de vous apporter compétence et excellence, nous sommes :</p>
        <p>- Inscrits à l'ORIAS sous le numéro 20004733</p>
        <p>- Certifiés AMF</p>
        <p>- Formés régulièrement sur les approches innovantes de l’investissement immobilier</p>
        <p>Les courtiers auxquelles vous allez vous adresser chez Courtage Patrimoine sont déjà tous multi-propriétaires immobilier. Nous souhaitons partager avec vous cette expérience solide du terrain et de la négociation, que ce soit pour un projet d'achat de résidence principale en vous aidant à trouver votre financement ou pour réaliser un investissement immobilier locatif de A à Z.</p>
      </div>
  <div class="mobile-div grey-background">
    <img src="/assets/img/visuel16.png" alt="personne adossée à un arbre" class="mobile-img">
  </div>
</div>
