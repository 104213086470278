<div *ngIf="innerWidth > 500">
<div class="all-wrapper">
  <div class="intro-wrapper">
    <div class="intro-container">
      <h3>L'investissement immobilier locatif clé en main</h3>
      <p>Vous souhaitez investir dans l'immobilier dans le but de créer des revenus complémentaires et de vous constituer un patrimoine ?</p>
      <p>Courtage Patrimoine se propose d'agir en qualité d'apporteur d'affaires afin de trouver un bien immobilier adapté à votre budget et votre fiscalité par une approche unique avec des experts multipropriétaires immobiliers.</p>
    </div>
    <apa-arrow-down-component></apa-arrow-down-component>
  </div>
  <div class="wrapper">
    <img src="/assets/img/visuel1.png" alt="femme à son bureau">
    <div class="wrapped-text-right wrapped-text">
      <h4><span class="dash-color">-</span> Étude Patrimoniale: analyse de la situation financière</h4>
      <p>Parce que votre situation est unique, vous rencontrez un conseiller en gestion de patrimoine afin de faire un bilan. Celui-ci étudiera les possibilités d'amélioration de votre situation pour ensuite déterminer le type de projet immobilier qui vous convient le mieux. Ce moment de partage est aussi l’occasion de répondre à toutes vos questions en matière fiscale et immobilière.</p>
    </div>
  </div>
  <div class="spark-container-right">
    <img class="spark" src="/assets/img/Spark.png" alt="sparks">
  </div>
  <div class="wrapper">
    <div class="wrapped-text-left wrapped-text">
      <h4><span class="dash-color">-</span> Recherche des meilleurs biens immobiliers</h4>
      <p>Une fois votre projet établi, nous procédons à une analyse du marché pour trouver les meilleures opportunités. En partenariat avec notre réseau professionnel, nous interrogeons les offres hors marché afin de dénicher les perles rares. Nous négocions ensuite les prix et rédigeons les formalismes d'acquisition pour préparer le compromis de vente dans de bonnes dispositions.</p>
    </div>
    <img src="/assets/img/visuel2.png" alt="homme d'affaires">
  </div>
  <div>
    <img class="spark" src="/assets/img/Spark.png" alt="sparks">
  </div>
  <div class="wrapper">
    <img src="/assets/img/visuel3.png" alt="un homme et une femme établissent une strategie">
    <div class="wrapped-text-right wrapped-text">
      <h4><span class="dash-color">-</span> Financement de l'opération auprès de nos partenaires</h4>
      <p>Courtage Patrimoine jouit d'une grande expérience dans l'obtention de financements. Nous mettons à l’œuvre toute notre ingénierie du crédit à votre service afin d'obtenir les meilleures conditions d'emprunt pour votre projet. Pour cela, nous constituons le dossier bancaire que nous présentons auprès des établissements de crédit. Une fois le financement obtenu, vous devenez propriétaire du bien après signature de l’acte de vente chez le notaire.</p>
    </div>
  </div>
  <div class="spark-container-right">
    <img class="spark" src="/assets/img/Spark.png" alt="sparks">
  </div>
  <div class="wrapper">
    <div class="wrapped-text-left wrapped-text">
      <h4><span class="dash-color">-</span> Travaux et mise en location du bien</h4>
      <p>La gestion des travaux et de l'aménagement peuvent s'avérer être des tâches complexes. Chez Courtage Patrimoine, on s'occupe de tout. Une fois le bien rénové, nous procédons aux formalités administratives et légales nécessaires. Le bien est fin prêt à être loué dans les meilleures conditions de rendement. Libre à vous d'en assurer la gestion ou de nous la déléguer. Vous percevrez ensuite les recettes de votre investissement. Nous restons à votre disposition tout au long de la vie du projet.</p>
    </div>
    <img src="/assets/img/visuel4.png" alt="une femme à son bureau fait une etude de marché">
  </div>
  <div class="rdv-wrapper">
    <h4>Une seule démarche: nous rencontrer. Ensuite, on s'occupe de tout.</h4>
    <div class="rdv-container">
      <apa-appointment-button></apa-appointment-button>
    </div>
  </div>
  <div class="video-container">
    <apa-video></apa-video>
  </div>
</div>
</div>

<div *ngIf="innerWidth <= 500" class="mobile-container">
  <div class="mobile-intro">
    <h3>L'investissement immobilier locatif clé en main</h3>
    <p>Vous souhaitez investir dans l'immobilier dans le but de créer des revenus complémentaires et de vous constituer un patrimoine ?</p>
    <p>Courtage Patrimoine se propose d'agir en qualité d'apporteur d'affaires afin de trouver un bien immobilier adapté à votre budget et votre fiscalité par une approche unique avec des experts multipropriétaires immobiliers.</p>
  </div>
  <div class="mobile-div">
    <img src="/assets/img/visuel1.png" alt="femme à son bureau" class="mobile-img">
  </div>
  <div class="aligned">
    <h4>Étude Patrimoniale: </h4>
    <h4>Analyse de la situation financière</h4>
    <p>Parce que votre situation est unique, vous rencontrez un conseiller en gestion de patrimoine afin de faire un bilan. Celui-ci étudiera les possibilités d'amélioration de votre situation pour ensuite déterminer le type de projet immobilier qui vous convient le mieux. Ce moment de partage est aussi l’occasion de répondre à toutes vos questions en matière fiscale et immobilière.</p>
  </div>
  <div class="mobile-div grey-background">
    <img src="/assets/img/visuel2.png" alt="homme d'affaires" class="mobile-img">
  </div>
  <div class="aligned grey-background">
    <h4>Recherche des meilleurs biens immobiliers</h4>
    <p>Une fois votre projet établi, nous procédons à une analyse du marché pour trouver les meilleures opportunités. En partenariat avec notre réseau professionnel, nous interrogeons les offres hors marché afin de dénicher les perles rares. Nous négocions ensuite les prix et rédigeons les formalismes d'acquisition pour préparer le compromis de vente dans de bonnes dispositions.</p>
  </div>
  <div class="mobile-div">
    <img src="/assets/img/visuel3.png" alt="un homme et une femme établissent une strategie" class="mobile-img">
  </div>
  <div class="aligned">
    <h4>Financement de l'opération auprès de nos partenaires</h4>
    <p>Courtage Patrimoine jouit d'une grande expérience dans l'obtention de financements. Nous mettons à l’œuvre toute notre ingénierie du crédit à votre service afin d'obtenir les meilleures conditions d'emprunt pour votre projet. Pour cela, nous constituons le dossier bancaire que nous présentons auprès des établissements de crédit. Une fois le financement obtenu, vous devenez propriétaire du bien après signature de l’acte de vente chez le notaire.</p>
  </div>
  <div class="mobile-div grey-background">
    <img src="/assets/img/visuel4.png" alt="une femme à son bureau fait une etude de marché" class="mobile-img">
  </div>
  <div class="aligned grey-background">
    <h4>Travaux et mise en location du bien</h4>
    <p>La gestion des travaux et de l'aménagement peuvent s'avérer être des tâches complexes. Chez Courtage Patrimoine, on s'occupe de tout. Une fois le bien rénové, nous procédons aux formalités administratives et légales nécessaires. Le bien est fin prêt à être loué dans les meilleures conditions de rendement. Libre à vous d'en assurer la gestion ou de nous la déléguer. Vous percevrez ensuite les recettes de votre investissement. Nous restons à votre disposition tout au long de la vie du projet.</p>
  </div>
  <div class="last-of-page grey-background">
    <h4>Une seule démarche: nous rencontrer.</h4>
    <h4>Ensuite, on s'occupe de tout.</h4>
  </div>

</div>



<!-- <div class="realisations">
  <hr>
  <div>
    <h4>Nos réalisations</h4>
  </div>
  <hr>
</div>

<div class="all-wrapper case-study">
  <h3>Etude de cas</h3>
  <div class="wrapper">
    <img src="/assets/img/visuel13.png" alt="homme devant une carte du monde">
    <div class="wrapped-text-right wrapped-text">
      <h4><span class="dash-color">-</span> Présentation d'un achat réalisé grâce à notre expertise</h4>
      <p>Appartement acheté sous-évalué dans la proche banlieue Bordelaise. Rénovation de l'appartement avec création de pièce (passage d’un T4 à un T5) afin de l'exploiter en colocation. Forte demande locative sur le marché. Achat réalisé fin 2019.</p>
    </div>
  </div>
  <img class="case-img" src="/assets/img/photoAppt.png" alt="photo d'un appartement avant/après">
  <div class="wrapper">
    <div class="wrapped-text-left wrapped-text">
      <h4><span class="dash-color">-</span> L'investissement en quelques chiffres</h4>
      <div class="investment-infos">
      <p>Prix total d'acquisition (notaire, travaux, etc) = 152 000€ <br>Prix estimé après rénovation = 180 000€<br>Financement 140 000€ sur 25 ans</p>
      </div>
      <div class="investment-infos">
      <p>Loyer charges comprises = 1 600€ / mois <br>Mensualité de crédit = 580€ / mois <br>Autres charges (assurance, impôt foncier, charges de copropriété, vacance locative estimée, etc..) = 450€ / mois</p>
      </div>
    </div>
    <img src="/assets/img/visuel12.png" alt="astronaute">
  </div>
  <div class="spark-container-right">
  </div>
  <div class="example-container">
    <div class="example-block">
      <p>Gain Brut Mensuel</p>
      <div class="example-number">+570€</div>
    </div>
    <div class="example-block">
      <p>Rentabilité Nette</p>
      <div class="example-number">
        12.63%
      </div>
    </div>
    <div class="example-block">
      <p>Plus-Value Latente</p>
      <div class="example-number">
        28.000€
      </div>
    </div>
  </div>
  <p class="example-quote">Les performances passées ne préjugent pas des performances futures</p>
  <div class="bottom-study-div">
    <div class="bottom-study-number">
      1
    </div>
  </div>
</div>

<div class="all-wrapper case-study">
  <h3>Etude de cas</h3>
  <div class="wrapper">
    <img src="/assets/img/visuel13.png" alt="homme devant une carte du monde">
    <div class="wrapped-text-right wrapped-text">
      <h4><span class="dash-color">-</span> Présentation d'un achat réalisé grâce à notre expertise</h4>
      <p>Bel investissement patrimonial pour cette opération. Optimisation d’un grand T2 (56m²) avec création d’une pièce supplémentaire et rénovation. Mise en place d’une société pour la dissociation du patrimoine. Achat réalisé début 2019.</p>
    </div>
  </div>
  <img class="case-img" src="/assets/img/photoAppt2.png" alt="photo d'un deuxieme appartement avant/après">
  <div class="wrapper">
    <div class="wrapped-text-left wrapped-text">
      <h4><span class="dash-color">-</span> L'investissement en quelques chiffres</h4>
      <div class="investment-infos">
      <p>Prix total d'acquisition (notaire, travaux, etc) = 118 000€ <br>Prix estimé après rénovation = 140 000€<br>Financement 120 000€ sur 25 ans</p>
      </div>
      <div class="investment-infos">
      <p>Loyer charges comprises = 1 000€ / mois <br>Mensualité de crédit = 495€ / mois <br>Autres charges (assurance, impôt foncier, charges de copropriété, vacance locative estimée, etc..) = 250€ / mois</p>
      </div>
    </div>
    <img src="/assets/img/visuel12.png" alt="astronaute">
  </div>
  <div class="spark-container-right">
  </div>
  <div class="example-container">
    <div class="example-block">
      <p>Gain Brut Mensuel</p>
      <div class="example-number">+ 255€</div>
    </div>
    <div class="example-block">
      <p>Rentabilité Nette</p>
      <div class="example-number">
        10.17%
      </div>
    </div>
    <div class="example-block">
      <p>Plus-Value Latente</p>
      <div class="example-number">
        22.000€
      </div>
    </div>
  </div>
  <p class="example-quote">Les performances passées ne préjugent pas des performances futures</p>
  <div class="bottom-study-div">
    <div class="bottom-study-number">
      2
    </div>
  </div>
</div>
</div> -->

<!-- Version Mobile  -->


