<div class="page-wrapper">
<div class="buttonContainer">
  <button class="previousButton" routerLink="/project-types">Retour</button>
</div>

<h1 *ngIf="projectTypeId">Edition type de projets</h1>
<h1 *ngIf="!projectTypeId">Nouveau type de projets</h1>
<form (ngSubmit)="projectTypeForm.valid && onSubmit()" #projectTypeForm="ngForm">
  <h4>Création du type</h4>
    <table>
      <tbody>
        <tr>
          <th class="tableRow"><label class="tableElement" for="name">Nom<span class="mandatoryField">*</span></label></th>
          <td class="tableInput">
            <input type="text" name="name" placeholder="Paiement commission" [(ngModel)]="projectTypeModel.name" #projecTypeName="ngModel" required>
            <div *ngIf="projectTypeForm.submitted && projecTypeName.errors?.required" class="errorMessage">Un nom requis.</div>
          </td>
          <td class="projectStatusRanking"></td>
          <td class="tableButton"></td>
        </tr>
      </tbody>
    </table>
  <h4>Documents nécessaires</h4>
  <div *ngIf="documentTypes">
    <table>
      <tbody>
        <tr *ngFor="let document of documentTypes; let i = index">
          <th class="tableRow"><label class="tableElement" for="documentType{{i+1}}">Document {{i+1}}</label></th>
          <td class="tableInput">
            <input type="text" name="documentType{{i}}" placeholder="Paiement commission" [(ngModel)]="document.name" #documentName="ngModel" required>
            <div *ngIf="projectTypeForm.submitted && documentName.errors?.required" class="errorMessage">Un nom de document requis.</div>
          </td>
          <td class="projectStatusRanking"></td>
          <td class="tableButton"><button class="deleteDocAndStatus redButton" type="button" (click)="deleteDocumentType(document)">-</button></td>
        </tr>
      </tbody>
    </table>
    <div class="buttonContainer">
      <button (click)="addDocumentType()">+</button>
    </div>
  </div>

  <h4>Etapes clés du projet</h4>
  <div *ngIf="projectStatuses">
    <table>
      <tbody>
        <tr *ngFor="let status of projectStatuses; let i = index">
          <th class="tableRow"><label class="tableElement" for="projectStatus{{i+1}}">Statut {{i+1}}</label></th>
          <td class="tableInput">
            <input type="text" name="projectStatus{{i}}" placeholder="Paiement commission" [(ngModel)]="status.name" #projectStatusName="ngModel" required>
            <div *ngIf="projectTypeForm.submitted && (projectStatusName.errors?.required || projectStatusRanking.errors?.required)" class="errorMessage">Un nom de status et un rang sont requis.</div>
          </td>
          <td class="projectStatusRanking"><input class="ranking"type="number" name="projectRanking{{i}}" placeholder="{{projectStatuses.length}}" [(ngModel)]="status.ranking" #projectStatusRanking="ngModel" required min="0"></td>
          <td class="tableButton"><button class="deleteDocAndStatus redButton" type="button" (click)="deleteProjectStatus(status)">-</button></td>
        </tr>
      </tbody>
    </table>
    <div class="buttonContainer">
      <button (click)="addProjectStatus()">+</button>
    </div>
  </div>
  <div class="buttonContainer">
    <button type="submit">Valider</button>
    <button class="redButton" (click)="resetForm()" type="reset">Annuler</button>
  </div>
</form>
</div>
