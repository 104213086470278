<footer *ngIf="innerWidth > 500">
  <div class="container">
    <img class="logo" routerLink="/home" src="assets/img/logo.png" alt="logo">
    <div class="footerElement">
      <h4>NOS COORDONNEES</h4>
      <p>+33 (0)6 34 72 49 33</p>
      <p>glenn.gendre@gmail.com</p>
      <p>Bordeaux</p>
    </div>
    <div class="footerElement">
      <h4>INFORMATIONS</h4>
      <a class ="footerLink" routerLink="/legal" routerLinkActive="active" > Mentions légales</a>
      <a class ="footerLink" routerLink="/faq" routerLinkActive="active">  F.A.Q</a>
    </div>
    <div class="footerElement">
      <h4>DIVERS</h4>
      <a class="lastP" routerLink="/corporate" routerLinkActive="active">Notre société</a>
    </div>
  </div>

</footer>
<footer class="mobile-footer-container" *ngIf="innerWidth <= 500">
  <div class="mobile-footer">
    <h4>NOS COORDONNEES</h4>
    <p>+33 (0)6 34 72 49 33</p>
    <p>glenn.gendre@gmail.com</p>
    <p>Bordeaux</p>
  </div>
  <div class="mobile-footer">
    <h4>INFORMATIONS</h4>
    <a class ="footerLink" routerLink="/legal" routerLinkActive="active" > Mentions légales</a>
    <a class ="footerLink" routerLink="/faq" routerLinkActive="active">  F.A.Q</a>
  </div>
  <div class="mobile-footer">
    <h4>DIVERS</h4>
    <a class="lastP" routerLink="/corporate" routerLinkActive="active">Notre société</a>
  </div>
  <div>

  </div>
</footer>
