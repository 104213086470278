<div class="page-wrapper">
<div class="buttonContainer">
  <button class="previousButton" routerLink="/client-list">Retour</button>
</div>
<h3 class="logged-title">Réglages</h3>
<div class="buttonContainer">
  <button routerLink="/user-types">Type d'utilisateurs</button>
  <button routerLink="/project-types">Type de projets</button>
</div>
</div>
