<div *ngIf="innerWidth > 500">
<div class="container">
  <apa-simulator></apa-simulator>
</div>
<apa-appointment-button></apa-appointment-button>
<div class="all-wrapper">

  <div class="mortgage-wrapper">
    <div class="mortgage-container">
      <h4>Obtenez les meilleurs taux pour votre projet immobilier</h4>
      <p>Exemple de mensualités pour 200 000€ empruntés</p>
    </div>
    <img class="central-big-img" src="/assets/img/mensualite.png" alt="exemples de mensualités pour 200 000€">
  </div>

  <div class="wrapper">
    <img src="/assets/img/visuel5.png" alt="dessin de maison">
    <div class="wrapped-text-right wrapped-text">
      <h4><span class="dash-color">-</span> Votre projet immobilier au coeur de notre préoccupation</h4>
      <p>Nos courtiers sont experts en tous types de financements. Nous vous garantissons une implication et des conseils sur mesure. Nous négocions pour vous les meilleures conditions d'emprunt pour vos projets.</p>
    </div>
  </div>

  <div class="spark-container-right">
    <img class="spark" src="/assets/img/Spark.png" alt="sparks">
  </div>

  <div class="wrapper">
    <div class="wrapped-text-left wrapped-text">
      <h4><span class="dash-color">-</span> Disponibilité et réactivité </h4>
      <p>Ce sont nos mots d'ordre. Nous vous garantissons un rendez-vous sous 24h pour l'étude et la faisabilité de votre projet. Les dossiers sont constitués rapidement et efficacement afin d'entamer les démarches auprès des établissements de crédits dans les plus brefs délais. Nous sommes là pour vous faire gagner du temps. C'est pourquoi nous adaptons notre agenda en fonction de vos disponibilités.</p>
    </div>
    <img src="/assets/img/visuel6.png" alt="dessin avec logo 24heures">
  </div>

  <div>
    <img class="spark" src="/assets/img/Spark.png" alt="sparks">
  </div>

  <div class="wrapper">
    <img src="/assets/img/visuel7.jpg" alt="dessin de projets entre les mains d'une personne">
    <div class="wrapped-text-right wrapped-text">
      <h4><span class="dash-color">-</span> Une équipe de professionnels expérimentés </h4>
      <p>Courtage Patrimoine, c'est le choix d'avoir des courtiers ayant une connaissance parfaite du financement, vous assurant d'importantes économies. Nous sommes spécialisés dans le prêt immobilier, les rachats et regroupements de crédits, ainsi que l'assurance emprunteur. Nous vous proposons une étude gratuite de vos encours actuels afin d'en déterminer les améliorations possibles.</p>
    </div>
  </div>

  <div class="spark-container-right">
    <img class="spark" src="/assets/img/Spark.png" alt="sparks">
  </div>

  <div class="wrapper">
    <div class="wrapped-text-left wrapped-text">
      <h4><span class="dash-color">-</span> Une entreprise moderne et dynamique</h4>
      <p>Nous faire confiance, c'est bénéficier d'un espace client personnalisé afin de suivre l'avancement de vos projets à distance. Mais c'est aussi travailler avec une équipe moderne et engagée. Chez Courtage Patrimoine, nous privilégions une approche humaine où votre courtier peut se rendre disponible à votre domicile, votre lieu de travail ou tout simplement autour d’un café pour échanger avec vous.</p>
    </div>
    <img src="/assets/img/visuel10.png" alt="logo de l'entreprise">
  </div>

  <div class="rdv-container">
    <apa-appointment-button></apa-appointment-button>
  </div>
</div>
</div>
<div *ngIf="innerWidth <= 500" class="mobile-container">
  <div class="mobile-intro">
    <h3>Demande de prêt immobilier</h3>
    <p>Vous êtes à la recherche d’un prêt immobilier pour votre projet d’acquisition ?</p>
    <p>Courtage patrimoine vous accompagne dans votre recherche de financement dans les meilleures conditions.</p>
  </div>
  <div class="mobile-div">
    <img src="/assets/img/visuel5.png" alt="dessin de maison" class="mobile-img">
  </div>
  <div class="aligned">
    <h4>Votre projet immobilier au coeur de notre préoccupation</h4>
      <p>Nos courtiers sont experts en tous types de financements. Nous vous garantissons une implication et des conseils sur mesure. Nous négocions pour vous les meilleures conditions d'emprunt pour vos projets.</p>
    </div>
  <div class="mobile-div grey-background">
    <img src="/assets/img/visuel6.png" alt="dessin avec logo 24heures" class="mobile-img">
  </div>
  <div class="aligned grey-background">
    <h4>Disponibilité et réactivité </h4>
      <p>Ce sont nos mots d'ordre. Nous vous garantissons un rendez-vous sous 24h pour l'étude et la faisabilité de votre projet. Les dossiers sont constitués rapidement et efficacement afin d'entamer les démarches auprès des établissements de crédits dans les plus brefs délais. Nous sommes là pour vous faire gagner du temps. C'est pourquoi nous adaptons notre agenda en fonction de vos disponibilités.</p>
    </div>
    <div class="mobile-div">
      <img src="/assets/img/visuel7.jpg" alt="dessin de projets entre les mains d'une personne" class="mobile-img">
    </div>
    <div class="aligned">
      <h4>Une équipe de professionnels expérimentés </h4>
      <p>Courtage Patrimoine, c'est le choix d'avoir des courtiers ayant une connaissance parfaite du financement, vous assurant d'importantes économies. Nous sommes spécialisés dans le prêt immobilier, les rachats et regroupements de crédits, ainsi que l'assurance emprunteur. Nous vous proposons une étude gratuite de vos encours actuels afin d'en déterminer les améliorations possibles.</p>
    </div>
  <div class="mobile-div grey-background">
    <img src="/assets/img/visuel10.png" alt="logo de l'entreprise" class="mobile-img">
  </div>
  <div class="aligned grey-background">
    <h4>Une entreprise moderne et dynamique</h4>
      <p>Nous faire confiance, c'est bénéficier d'un espace client personnalisé afin de suivre l'avancement de vos projets à distance. Mais c'est aussi travailler avec une équipe moderne et engagée. Chez Courtage Patrimoine, nous privilégions une approche humaine où votre courtier peut se rendre disponible à votre domicile, votre lieu de travail ou tout simplement autour d’un café pour échanger avec vous.</p>
    </div>
</div>
