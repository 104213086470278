<div *ngIf="innerWidth > 500">
<div class="all-wrapper">
  <div class="intro-wrapper">
    <div class="intro-container">
      <h3>Les conseils financiers sur mesure</h3>
      <p>Vous souhaitez mieux maîtriser votre budget ? Répondre à vos questions d'ordre financier avec un regard averti ? Mettre en place des systèmes ingénieux afin de générer des revenus complémentaires ? Ou simplement optimiser votre situation financière ?</p>
      <p>Courtage Patrimoine met à disposition de nombreux services qui vous sont proposés en fonction de vos besoins, ainsi que des conseils et un accompagnement adaptés à vos problématiques.</p>
    </div>
    <apa-arrow-down-component></apa-arrow-down-component>
  </div>

  <div class="wrapper">
    <img src="/assets/img/visuel11.png" alt="femme à son bureau">
    <div class="wrapped-text-right wrapped-text">
      <h4><span class="dash-color">-</span> L'audit patrimonial, une approche globale de votre environnement</h4>
      <p>Choisissez l'expertise adéquate pour auditer votre patrimoine. Nos connaissances en stratégies financières vous feront bénéficier d’un accompagnement judicieux afin d’optimiser vos revenus, créer de la valeur et réduire votre fiscalité.</p>
    </div>
  </div>

  <div class="spark-container-right">
    <img class="spark" src="/assets/img/Spark.png" alt="sparks">
  </div>

  <div class="wrapper">
    <div class="wrapped-text-left wrapped-text">
      <h4><span class="dash-color">-</span> L'optimisation d'un patrimoine immobilier</h4>
      <p>Location nue, LMNP, LMP, SCI, SARL, SAS, dispositifs fiscaux sont des exemples de statuts liés à une exploitation immobilière. Vous ne savez pas lequel est le plus adapté à votre situation actuelle ou vos projets futurs ? Nous vous proposons d'étudier votre cas et de mettre en comparaison les avantages et inconvénients de chacun d'entre eux pour vous faire adopter la ou les structures adaptées à votre situation.</p>
    </div>
    <img src="/assets/img/visuel8.png" alt="dessin d'immeuble">
  </div>

  <div>
    <img class="spark" src="/assets/img/Spark.png" alt="sparks">
  </div>

  <div class="wrapper">
    <img src="/assets/img/visuel9.jpg" alt="rose des vents">
    <div class="wrapped-text-right wrapped-text">
      <h4><span class="dash-color">-</span> Transmission de patrimoine</h4>
      <p>Dès lors que l’on possède des actifs, il est important de réfléchir à la question de leur transmission. En effet, c’est une action qui doit être établie au préalable afin d’en optimiser les bénéfices. C’est pourquoi nous sommes à même de vous guider dans ces choix en structurant votre patrimoine afin d'avoir une vision claire.</p>
    </div>
  </div>

  <div class="rdv-container">
    <apa-appointment-button></apa-appointment-button>
  </div>
</div>
</div>
<div *ngIf="innerWidth <= 500">
  <div class="mobile-intro">
    <h3>Les conseils financiers sur mesure</h3>
    <p>Vous souhaitez mieux maîtriser votre budget ? Répondre à vos questions d'ordre financier avec un regard averti ? Mettre en place des systèmes ingénieux afin de générer des revenus complémentaires ? Ou simplement optimiser votre situation financière ?</p>
    <p>Courtage Patrimoine met à disposition de nombreux services qui vous sont proposés en fonction de vos besoins, ainsi que des conseils et un accompagnement adaptés à vos problématiques.</p>
  </div>
  <div class="mobile-div">
    <img src="/assets/img/visuel11.png" alt="femme à son bureau" class="mobile-img">
  </div>
  <div class="aligned">
    <h4>L'audit patrimonial, une approche globale de votre environnement</h4>
      <p>Choisissez l'expertise adéquate pour auditer votre patrimoine. Nos connaissances en stratégies financières vous feront bénéficier d’un accompagnement judicieux afin d’optimiser vos revenus, créer de la valeur et réduire votre fiscalité.</p>
  </div>
  <div class="mobile-div grey-background">
    <img src="/assets/img/visuel8.png" alt="dessin d'immeuble" class="mobile-img">
  </div>
  <div class="aligned grey-background">
    <h4>L'optimisation d'un patrimoine immobilier</h4>
      <p>Location nue, LMNP, LMP, SCI, SARL, SAS, dispositifs fiscaux sont des exemples de statuts liés à une exploitation immobilière. Vous ne savez pas lequel est le plus adapté à votre situation actuelle ou vos projets futurs ? Nous vous proposons d'étudier votre cas et de mettre en comparaison les avantages et inconvénients de chacun d'entre eux pour vous faire adopter la ou les structures adaptées à votre situation.</p>
  </div>
  <div class="mobile-div">
    <img src="/assets/img/visuel9.jpg" alt="rose des vents" class="mobile-img">
  </div>
  <div class="aligned">
    <h4>Transmission de patrimoine</h4>
      <p>Dès lors que l’on possède des actifs, il est important de réfléchir à la question de leur transmission. En effet, c’est une action qui doit être établie au préalable afin d’en optimiser les bénéfices. C’est pourquoi nous sommes à même de vous guider dans ces choix en structurant votre patrimoine afin d'avoir une vision claire.</p>
  </div>
</div>
