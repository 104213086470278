<div class="page-wrapper">
<h4>Retour aux menus précédents</h4>
<div class="buttonContainer" *ngIf="projectTypes">
  <button class="previousButton" routerLink="/client-projects/{{selectedUserId}}">Projets</button>
  <button class="previousButton" routerLink="/client-list">Liste clients</button>
</div>

<h1 *ngIf="selectedUser && !selectedUser.companyName ">M./Mme. {{selectedUser.firstName}} {{selectedUser.lastName.toUpperCase()}}</h1>
<h1 *ngIf="selectedUser && selectedUser.companyName && selectedUser.companyName!==''">Société {{selectedUser.companyName}}</h1>

<form *ngIf="selectedUser" (ngSubmit)="projectForm.valid && onSubmit()" #projectForm="ngForm">
  <div class="projectContainer" *ngIf="projectTypes">
    <button class="projectTypeButton" type="button" *ngFor="let projectType of projectTypes" (click)="selectProjectType(projectType)">{{projectType.name}}</button>
  </div>
  <div class="formSubcontainer">
    <label for="projectName">Nom projet</label>
    <input type="text" name="projectName" [(ngModel)]="projectModel.name" #projectName="ngModel" required>
  </div>
  <div class="formSubcontainer">
    <label for="amount">Montant</label>
    <input type="number" name="amount" [(ngModel)]="projectModel.amount" #amount="ngModel" required>
  </div>
  <div class="formSubcontainer">
    <label for="creationDate">Date de création</label>
    <input type="date" name="creationDate" [ngModel]="projectModel.creationDate | date: 'yyyy-MM-dd'" (ngModelChange)="projectModel.creationDate=$event" #creationDate="ngModel" required>
  </div>
  <div class="formSubcontainer">
    <label for="closingDate">Date de clôture</label>
    <input type="date" name="closingDate" [ngModel]="projectModel.closingDate | date: 'yyyy-MM-dd'" (ngModelChange)="projectModel.closingDate=$event" #closingDate="ngModel">
  </div>
  <div class="formSubcontainer">
    <label for="projectStatus">Statut</label>
    <select type="text" name="projectStatus" [ngModel]="projectModel.projectStatus.id" (ngModelChange)="selectStatus($event)" #projectStatus="ngModel">
      <option value="{{status.id}}" *ngFor="let status of projectModel.projectType.projectStatuses">{{status.name}}</option>
    </select>
  </div>

  <div class="buttonContainer">
    <button type="submit">Valider</button>
    <button class="redButton" type="reset">Annuler</button>
  </div>
</form>
</div>
