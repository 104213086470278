<div class="container">
  <div class="title"> Simulateur de Capacité d'Emprunt</div>
  <form id="form" name="simulateur" (ngSubmit)="simulatorData.calculate()">
    <div class="formElementContainer">
      <div class="subcontainer">
        <div class="element">
          <div class="input-description">Revenus nets imposable / mois</div>
          <input class="lign" type="number" [(ngModel)]="simulatorData.netIncome" name="revenus net" />
        </div>
        <div class="element">
          <div class="input-description">Loyers à charge après acquisition / mois</div>
          <input class="lign" type="number" [(ngModel)]="simulatorData.rentExpense" name="charges loyers" />
        </div>
        <div class="element">
          <div class="input-description">Durée de crédit / an</div>
          <input class="lign" type="number" [(ngModel)]="simulatorData.duration"name="duree" />
        </div>
      </div>
      <div class="subcontainer">
        <div class="element">
          <div class="input-description">Revenus immobilier locatifs / mois</div>
          <input class="lign" type="number" [(ngModel)]="simulatorData.rentIncome" name="revenus locatifs" />
        </div>
        <div class="element">
          <div class="input-description">Crédits en cours après acquisition / mois</div>
          <input class="lign" type="number" [(ngModel)]="simulatorData.loanExpense" name="charges credits" />
        </div>
        <div class="element">
          <div class="input-description">Taux d'intérêt %</div>
          <input class="lign" type="number" [(ngModel)]="simulatorData.rate" name="taux" />
        </div>
      </div>
      <div class="subcontainer">
        <div class="element">
          <div class="input-description">Autres revenus / mois</div>
          <input class="lign" type="number" [(ngModel)]="simulatorData.otherIncome" name="revenus autres" />
        </div>
        <div class="element">
          <div class="input-description">Autres charges / mois</div>
          <input class="lign" type="number" [(ngModel)]="simulatorData.otherExpense" name="charges autres" />
        </div>
        <div class="button-element">
          <button type="submit"> Valider</button>
        </div>
      </div>
      <div class="subcontainer last">
        <div class="result">
          <div class="input-description">Total revenus / mois </div>
          <div class="lign result-lign">
            {{simulatorData.totalIncome | number:'':'fr-FR'}} €
          </div>
        </div>
        <div class="result">
          <div class="input-description">Total charges / mois </div>
          <div class="lign result-lign">
            {{simulatorData.totalExpense | number:'':'fr-FR'}} €
          </div>
        </div>
        <div class="result">
          <div class="input-description">Montant Empruntable :</div>
          <div class="lign result-lign">
            {{simulatorData.loanCapacity | number:'':'fr-FR'}} €
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
