<div id="main">

  <div class="paragraph">
    <h3 class="subtitle">
      MENTIONS LEGALES :
    </h3>
    <hr class="solid">

    <p>
      COURTAGE PATRIMOINE, située au 37 avenue Jean Lassauguette Bat E Apt 108 33270 Floirac est une EURL au capital de
      1 000 €, enregistrée au RCS de Bordeaux sous le numéro N° 883 700 197.
    </p>
    <p>
      Enregistrée à l’ORIAS sous le N° 20004733 (www.orias.fr) en qualité de : 
    </p>
    <p class="aurias">
      (COBSP) Courtier en opérations de banque et en services de paiement
    </p>
    <p>
      Directeur de la publication du site : Mr Glenn Gendre (gérant COURTAGE PATRIMOINE)
    </p>
    <p>
      Hébergeur : OVH
    </p>
    <p>
      Webmaster : WildCodeSchool
    </p>
    <p>
      Graphiste Images : Léa Diaz
    </p>

  </div>

  <div class="paragraph">
    <p class="subtitle">
      Courtage en financement et assurance :
    </p>
    <p>
      Courtier n’étant pas soumis à une obligation contractuelle de travailler exclusivement avec une ou plusieurs
      entreprises d'assurances ou bancaires.
    </p>
    <p>
      Il peut vous être communiqué, sur simple demande, le nom des entreprises avec lesquelles le cabinet travaille.
    </p>
    <p>
      Entreprises avec lesquelles il existe un lien financier (si le cabinet détient une participation > à 10 % des
      droits de vote ou du capital d’une entreprise d’assurance ou si cette dernière détient une participation > à 10 %
      des droits de vote ou du capital du cabinet) : Néant
    </p>
    <p>
      COURTAGE PATRIMOINE ne déclare pas avoir enregistré au cours de l’année 2020 une part supérieure au tiers de son
      chiffre d’affaires au titre de l’activité en assurance avec un établissement bancaire.
    </p>
  </div>

  <div class="paragraph">
    <p class="subtitle">
      Informations sur les modes de communications :
    </p>
    <p id="rgpd">
      Dans le cadre de notre relation contractuelle, nous pourrons communiquer par courrier postal ou par tous moyens de
      télécommunications
    </p>
  </div>

  <div class="paragraph">
    <p class="subtitle">
      Protection des données personnelles :
    </p>
    <p>
      Dans le cadre de nos relations professionnelles, nous sommes amenés à collecter, traiter et détenir des
      informations vous concernant.
    </p>
    <p>
      Les données personnelles que vous nous transmettez dans le cadre de notre activité et des services que nous vous
      proposons sont collectées et traitées par Mr Glenn GENDRE en qualité de responsable de traitement au sens des
      dispositions du Règlement Général sur la protection des données personnelles (RGPD). </p>
    <p>
      Ces données personnelles sont collectées, selon le cas, sur des bases légales différentes (votre consentement, la
      nécessité contractuelle, le respect d’une obligation légale et/ou encore l’intérêt légitime du Responsable de
      traitement).
    </p>
    <p>
      Concernant vos proches, nous vous remercions de les tenir informés des modalités du présent traitement de leurs
      données personnelles.
    </p>
    <p>
      Les données collectées vous concernant vous et vos proches seront conservées pendant toute la durée de nos
      relations contractuelles et ensuite en archive pendant un délai de 5 ans, à défaut des délais plus courts ou plus
      longs spécialement prévus notamment en cas de litige.
    </p>
    <p>
      Vous disposez sur ces données d’un droit d’accès, de rectification, et limitation, ainsi que d’un droit
      d’opposition et de portabilité conformément à la loi. Si vous souhaitez exercer ces droits, vous pouvez nous
      contacter par courrier à l’adresse suivante : glenn.gendre@gmail.com
    </p>
    <p>
      Ce site a fait l’objet d’une déclaration auprès de la CNIL sous le N° DPO-86587.
    </p>
    <p>
      Vous disposez également du droit d’introduire une réclamation auprès de la CNIL (3 Place de Fontenoy, 75007 Paris)
    </p>
  </div>

  <div class="paragraph">
    <p class="subtitle">
      Assurance professionnelle :
    </p>
    <p>
      Assurance RCP : AIG Europe SA, Succursale pour la France Tour CB21 - 16 Place de l’Iris, 92400 Courbevoie, numéro
      RD01300296N. </p>
  </div>

  <div class="paragraph">
    <p class="subtitle">
      Litige :
    </p>
    <p>
      En cas de litige ou de réclamation du client, les parties contractantes s’engagent à rechercher en premier lieu un
      arrangement amiable.
    </p>
    <p>
      Le client pourra présenter sa réclamation à l’adresse mail : glenn.gendre@gmail.com qui disposera de 10 jours pour
      en accusé réception, puis de 2 mois à compter de la réception de la réclamation pour y répondre.
    </p>
    <p>
      A défaut d’arrangement amiable, les parties pourront en 2nd lieu informer le médiateur de la consommation :
    </p>
    <p>
      Pour les autres activités : Centre de Médiation et d’Arbitrage de Paris (CMAP), Service Médiation de la
      consommation, 39 avenue Franklin D. Roosevelt 75008 PARIS Médiateurs : Christophe AYELA, Jean-Marc BLAMOUTIER,
      Catherine BOINEAU, Gilles CHARLOT, Michel GUIGAL (www.mediateur-conso.cmap.fr – consommation@cmap.fr)
    </p>
    <p>
      En cas d’échec, le litige pourrait être porté devant les tribunaux compétents.
    </p>
  </div>

  <div class="paragraph">
    <p class="subtitle">
      Cookies :
    </p>
    <p>
      L’utilisateur est informé, que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son
      logiciel de navigation.
    </p>
    <p>
      Le cookie est un bloc de données qui ne permet pas d’identifier l’utilisateur mais sert à enregistrer des
      informations relatives à la navigation de celui-ci sur le site. L’utilisation de ces cookies a pour finalité de
      procéder à des analyses de fréquentation, mesures d’audience… afin d’améliorer la qualité du site. Le paramétrage
      de votre logiciel de navigation vous permet d’être informé de la présence du témoin de connexion et,
      éventuellement, de le refuser . En cas de refus des témoins de connexions, certains services ne sont plus
      accessibles.
    </p>
  </div>

  <div class="paragraph">
    <p class="subtitle">
      Données légales :
    </p>
    <p>
      <span> COURTAGE PATRIMOINE </span> se réserve le droit de modifier ou d’actualiser la présente charte de
      protection des données personnelles à tout moment. Il est donc dans l’intérêt de l’Utilisateur de se tenir informé
      des changements effectués.
    </p>
  </div>
  <div class="lastMention">
    Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous engager.
  </div>
