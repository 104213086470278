<div class="page-wrapper">
<img *ngIf="userTypes" class="settingIcon" routerLink="/settings" src="assets/img/settings.svg" alt="settings">
<h3 class="logged-title">Liste des clients</h3>
<div class="container">
  <button (click)="navigateToCreateClient()">Nouveau client</button>
</div>
<h4 class="logged-title">Selectionnez le type de clients</h4>
<div class="container">
  <button class="clientType" (click)="selectUserType(userType)" *ngFor="let userType of userTypes">{{userType.name}}</button>
</div>
<div class="container">
  <form *ngIf="userTypeName" #form="ngForm">
    <label for="searchByName">Recherche client</label>
    <input type="search" name="searchByName" id="searchByName" [(ngModel)]="filterSearch" (ngModelChange)="filterUsers($event)">
  </form>
</div>
<Table>
  <thead>
    <tr *ngIf="userTypeName">
      <th *ngIf="displayClientInfo">Prénom<hr></th>
      <th *ngIf="displayClientInfo">Nom<hr></th>
      <th *ngIf="displayCompanyInfo">Raison sociale<hr></th>
      <th *ngIf="displayCompanyInfo">N° siret<hr></th>
      <th class="iconsColumn">Editer<hr></th>
      <th class="iconsColumn">Projets<hr></th>
      <th class="iconsColumn">Supprimer<hr></th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let user of filteredUsers">
      <td *ngIf="displayClientInfo">{{user.firstName}}</td>
      <td *ngIf="displayClientInfo">{{user.lastName.toUpperCase()}}</td>
      <td *ngIf="displayCompanyInfo">{{user.companyName}}</td>
      <td *ngIf="displayCompanyInfo">{{user.siretNumber}}</td>
      <td class="iconsColumn"><img class="icons" (click)="navigateToUserDetails(user.id)" src="assets/img/edit.svg" alt=""></td>
      <td class="iconsColumn"><img class="icons" (click)="navigateToUserProjects(user.id)" src="assets/img/folder.svg" alt=""></td>
      <td class="iconsColumn"><img class="icons" (click)="deleteUser(user.id)" src="assets/img/trash.svg" alt=""></td>
    </tr>
  </tbody>
</Table>
</div>
