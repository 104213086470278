<div *ngIf="innerWidth > 500" class="faq-wrapper">
  <h3>F.A.Q</h3>
  <hr class="solid">
  <div>
    <h4>Qu’est-ce qu’un service de courtage en immobilier ?</h4>
    <p>Ce service vous permet de réaliser une transaction immobilière afin d’acquérir un bien. Le courtier s’appuie sur un mandat de recherche qui l’engage à vous trouver un financement adapté à votre budget, notamment par la négociation des taux et des différents frais liés au prêt. Un courtier est l'intermédiaire entre vos projets et les établissements bancaires.</p>
  </div>
  <div>
    <h4>A quoi sert un apporteur d’affaires en immobilier ?</h4>
    <p>Son rôle est de vous proposer des biens adaptés à vos ambitions à partir d’une étude financière approfondie. Son large réseau et sa connaissance du marché immobilier vous permettra de saisir des opportunités difficilement accessibles en tant que particulier. Contrairement à l’agent immobilier, il travaille au compte de l’acheteur et perçoit sa rémunération de celui-ci.</p>
  </div>
  <div>
    <h4>A qui s'adressent vos services d'apporteur d'affaires ?</h4>
    <p>A toutes personnes souhaitant investir de façon sereine, tout en bénéficiant de l'expertise de financiers professionnels.</p>
    <p>L’investissement immobilier locatif est accessible aussi bien à l'acheteur aux revenus modestes qu'à celui ayant des revenus plus aisés. En accompagnant des profils variés, nous souhaitons avant tout rendre accessible l’accès à la propriété, tout en créant des revenus complémentaires aux acquéreurs. Notre forte expérience et nos accompagnements uniques seront vos alliés pour un projet immobilier réussi.</p>
    <p>Nous nous chargeons de toutes les étapes de l’acquisition :</p>
    <p>- Prospection de biens immobiliers – visites – offres d’achats</p>
    <p>- Signature des compromis</p>
    <p>- Financement des biens</p>
    <p>- Signature des actes de vente</p>
    <p>- Aide à la valorisation des biens (travaux, aménagements en fonction du type de location, etc.)</p>
    <p>- Aide à la location (rédaction des baux, conseils de gestion, etc.)</p>
    <p>C'est un réel gain de temps pour vous et l'assurance de ne pas perdre de l'argent dans les différentes étapes d'acquisition immobilière par inexpérience et méconnaissance du milieu.</p>
  </div>
  <div>
    <h4>Pourquoi avoir recours à un gestionnaire de patrimoine ?</h4>
    <p>L'expertise que nous possédons vous permet de réaliser des projets solides, créant ainsi des revenus complémentaires. C'est aussi l'opportunité d'améliorer votre fiscalité (choix de statuts, dissociation des activités, etc. ), de prévoir votre transmission de patrimoine et de développer une vision pertinente de vos finances sur le long terme.</p>
  </div>
  <div>
    <h4>Quelle est la stratégie de Courtage Patrimoine pour couvrir ses clients de tout conflit d’intérêts ?</h4>
    <p>Courtage Patrimoine est une société de services financiers indépendante. Nous sommes au service exclusif de nos clients.</p>
  </div>
  <div>
    <h4>Quels sont vos tarifs ?</h4>
    <p>Nos tarifs d'apporteurs d'affaires sont calculés en fonction de chaque projet. En effet, le temps alloué sur une situation dépend des besoins en ingénierie financière. Les actions qui en découlent vont nécessiter plus ou moins d’investissement de la part de notre société. Les honoraires sont donc établis après analyse de votre situation, lors d'un premier rendez-vous où nous vous fournissons un devis gratuit.</p>
    <p>Par ailleurs, dans le cadre d'un courtage en financement, les tarifs sont les suivants :</p>
    <table style="width:100%">
      <tr>
        <th>Montant du financement</th>
        <th>Honoraires de courtage</th>
      </tr>
      <tr>
        <td>0€ à 80 000€</td>
        <td>500€ + 0,5% du montant emprunté</td>
      </tr>
      <tr>
        <td>80 001€ à 120 000€</td>
        <td>350€ + 0,7% du montant emprunté</td>
      </tr>
      <tr>
        <td>120 001€ à 160 000€</td>
        <td>500€ + 0,6% du montant emprunté</td>
      </tr>
      <tr>
        <td>160 001€ à 200 000€</td>
        <td>500€ + 0,5% du montant emprunté</td>
      </tr>
      <tr>
        <td>200 001€ à 400 000€</td>
        <td>1 500,00 €</td>
      </tr>
      <tr>
        <td>400 001€ à 600 000€</td>
        <td>1 700,00 €</td>
      </tr>
      <tr>
        <td>600 000€ et plus</td>
        <td>2 000,00 €</td>
      </tr>
    </table>
    <p>Pour vous donner plus de clarté, les honoraires de notre société vous seront toujours présentés dans le coût total de l’opération financière.</p>
  </div>
  <div>
    <h4>Vos services sont-ils finançables par un établissement bancaire ?</h4>
    <p>Les honoraires d'apporteurs d'affaires dans le cadre d'un investissement immobilier locatif et les honoraires de courtage sont finançables auprès des banques. Cela dépend cependant de votre capacité d'emprunt et des établissements de crédit sollicités.</p>
  </div>
  <div>
    <h4>Les honoraires sont-ils assimilables à des charges d'exploitation ?</h4>
    <p>Les honoraires facturés dans le cadre de nos activités peuvent être déduits et assimilés à des charges. Cela dépend de la forme juridique que vous avez choisie pour votre achat/investissement. Par exemple : les honoraires de courtage en financement peuvent être déduits au réel dans le cadre d'une exploitation locative en nom propre ou par le bais de sociétés d'exploitation.</p>
  </div>
  <div>
    <h4>Dans le cadre d'une prestation de courtage, si vous ne me trouvez pas de financement, dois-je avancer des frais ?</h4>
    <p>Absolument pas ! Courtage Patrimoine se rémunère uniquement en cas de réussite de votre financement. De plus, la loi MURCEF stipule qu’un courtier ne peut percevoir d'honoraires avant le déblocage des fonds par la banque.</p>
  </div>
  <div>
    <h4>Comment procédez-vous pour la recherche des biens immobiliers ?</h4>
    <p>Chez Courtage Patrimoine, nous sommes en constante observation du marché immobilier. Nous procédons avec une stratégie simple : l'arbitrage. Cela consiste à la prospection de biens sous-évalués par rapport à leur valeur de vente possible. Nous disposons d'outils et d’algorithmes permettant de trouver des biens qui seront rentables dès leur acquisition. </p>
    <p>Nous sommes également présents sur un large réseau de professionnels de l'immobilier qui peuvent nous alerter avant la mise en vente d'un bien avantageux sur le marché. Ces informations réservées aux professionnels et non accessibles aux particuliers sont appelées les opportunités « off-market ».</p>
    <p>Enfin, notre expertise nous permet d’évaluer le potentiel d’un bien et ses plus-values possibles afin de vous offrir des opportunités uniques d’investissement.</p>
  </div>
  <div>
    <h4>Dans quel type de ville proposez-vous d'investir ?</h4>
    <p>Le choix de la localité du bien repose sur plusieurs critères qui sont dépendant de votre situation et des paramètres inhérents à chaque ville. En effet, la volatilité des prix n'est pas la même d’une ville à une autre. Par exemple, une ville où l'appréciation immobilière est faible mais constante ne subira pas les mêmes dépréciations en cas de décroissance du marché qu'une ville où l'appréciation est très forte.</p>
  </div>
  <div>
    <h4>Étant présents sur Bordeaux, pouvez-vous travailler avec des personnes de la France entière ?</h4>
    <p>Tout à fait. Nous sommes une entreprise dynamique et connectée. Nous effectuons des déplacements régulièrement dans le cadre de la prospection immobilière et notre espace client nous permet de travailler à distance sur l'ensemble des prestations. Nous travaillons également en visio-conférence avec nos clients.</p>
  </div>
  <div>
    <h4>Est-il sûr d’investir dans l’immobilier aujourd’hui ? </h4>
    <p>L'immobilier représente la classe d'actif où les investisseurs sont le plus en confiance. Sa matérialisation physique la rend plus compréhensible qu'un titre financier. De plus, se loger reste l'un des 4 besoins fondamentaux dans notre société. C'est donc un placement reconnu au travers des âges. Cependant, comme tout investissement, il comporte un risque de perte en capital lié à la fluctuation des prix de l'immobilier, eux-mêmes corrélés à d'autres facteurs (l'accès aux crédits, l'offre et la demande, etc.). </p>
  </div>
  <div>
    <h4>Qu’est-ce qui différencie votre société de gestion du patrimoine d’une autre sur le marché de l’immobilier ?</h4>
    <p>Nous ne commercialisons pas les biens de société de promotion immobilière ou autres. Notre approche n'est pas de voir si un produit d'autrui vous correspond, mais de partir de votre situation afin de créer un produit adapté à tous vos paramètres financiers. </p>
  </div>
  <div>
    <h4>Vous êtes plutôt thé ou café ?</h4>
    <p>Plutôt thé. Thé blanc de Pai Mu Tan…</p>
  </div>
</div>

<div *ngIf="innerWidth <= 500" class="mobile-container">
  <h3 class="intro-title">F.A.Q</h3>
  <div>
    <h4>Qu’est-ce qu’un service de courtage en immobilier ?</h4>
    <p>Ce service vous permet de réaliser une transaction immobilière afin d’acquérir un bien. Le courtier s’appuie sur un mandat de recherche qui l’engage à vous trouver un financement adapté à votre budget, notamment par la négociation des taux et des différents frais liés au prêt. Un courtier est l'intermédiaire entre vos projets et les établissements bancaires.</p>
  </div>
  <div>
    <h4>A quoi sert un apporteur d’affaires en immobilier ?</h4>
    <p>Son rôle est de vous proposer des biens adaptés à vos ambitions à partir d’une étude financière approfondie. Son large réseau et sa connaissance du marché immobilier vous permettra de saisir des opportunités difficilement accessibles en tant que particulier. Contrairement à l’agent immobilier, il travaille au compte de l’acheteur et perçoit sa rémunération de celui-ci.</p>
  </div>
  <div>
    <h4>A qui s'adressent vos services d'apporteur d'affaires ?</h4>
    <p>A toutes personnes souhaitant investir de façon sereine, tout en bénéficiant de l'expertise de financiers professionnels.</p>
    <p>L’investissement immobilier locatif est accessible aussi bien à l'acheteur aux revenus modestes qu'à celui ayant des revenus plus aisés. En accompagnant des profils variés, nous souhaitons avant tout rendre accessible l’accès à la propriété, tout en créant des revenus complémentaires aux acquéreurs. Notre forte expérience et nos accompagnements uniques seront vos alliés pour un projet immobilier réussi.</p>
    <p>Nous nous chargeons de toutes les étapes de l’acquisition :</p>
    <p>- Prospection de biens immobiliers – visites – offres d’achats</p>
    <p>- Signature des compromis</p>
    <p>- Financement des biens</p>
    <p>- Signature des actes de vente</p>
    <p>- Aide à la valorisation des biens (travaux, aménagements en fonction du type de location, etc.)</p>
    <p>- Aide à la location (rédaction des baux, conseils de gestion, etc.)</p>
    <p>C'est un réel gain de temps pour vous et l'assurance de ne pas perdre de l'argent dans les différentes étapes d'acquisition immobilière par inexpérience et méconnaissance du milieu.</p>
  </div>
  <div>
    <h4>Pourquoi avoir recours à un gestionnaire de patrimoine ?</h4>
    <p>L'expertise que nous possédons vous permet de réaliser des projets solides, créant ainsi des revenus complémentaires. C'est aussi l'opportunité d'améliorer votre fiscalité (choix de statuts, dissociation des activités, etc. ), de prévoir votre transmission de patrimoine et de développer une vision pertinente de vos finances sur le long terme.</p>
  </div>
  <div>
    <h4>Quelle est la stratégie de Courtage Patrimoine pour couvrir ses clients de tout conflit d’intérêts ?</h4>
    <p>Courtage Patrimoine est une société de services financiers indépendante. Nous sommes au service exclusif de nos clients.</p>
  </div>
  <div>
    <h4>Quels sont vos tarifs ?</h4>
    <p>Nos tarifs d'apporteurs d'affaires sont calculés en fonction de chaque projet. En effet, le temps alloué sur une situation dépend des besoins en ingénierie financière. Les actions qui en découlent vont nécessiter plus ou moins d’investissement de la part de notre société. Les honoraires sont donc établis après analyse de votre situation, lors d'un premier rendez-vous où nous vous fournissons un devis gratuit.</p>
    <p>Par ailleurs, dans le cadre d'un courtage en financement, les tarifs sont les suivants :</p>
    <table style="width:100%">
      <tr>
        <th>Montant du financement</th>
        <th>Honoraires de courtage</th>
      </tr>
      <tr>
        <td>0€ à 80 000€</td>
        <td>500€ + 0,5% du montant emprunté</td>
      </tr>
      <tr>
        <td>80 001€ à 120 000€</td>
        <td>350€ + 0,7% du montant emprunté</td>
      </tr>
      <tr>
        <td>120 001€ à 160 000€</td>
        <td>500€ + 0,6% du montant emprunté</td>
      </tr>
      <tr>
        <td>160 001€ à 200 000€</td>
        <td>500€ + 0,5% du montant emprunté</td>
      </tr>
      <tr>
        <td>200 001€ à 400 000€</td>
        <td>1 500,00 €</td>
      </tr>
      <tr>
        <td>400 001€ à 600 000€</td>
        <td>1 700,00 €</td>
      </tr>
      <tr>
        <td>600 000€ et plus</td>
        <td>2 000,00 €</td>
      </tr>
    </table>
    <p>Pour vous donner plus de clarté, les honoraires de notre société vous seront toujours présentés dans le coût total de l’opération financière.</p>
  </div>
  <div>
    <h4>Vos services sont-ils finançables par un établissement bancaire ?</h4>
    <p>Les honoraires d'apporteurs d'affaires dans le cadre d'un investissement immobilier locatif et les honoraires de courtage sont finançables auprès des banques. Cela dépend cependant de votre capacité d'emprunt et des établissements de crédit sollicités.</p>
  </div>
  <div>
    <h4>Les honoraires sont-ils assimilables à des charges d'exploitation ?</h4>
    <p>Les honoraires facturés dans le cadre de nos activités peuvent être déduits et assimilés à des charges. Cela dépend de la forme juridique que vous avez choisie pour votre achat/investissement. Par exemple : les honoraires de courtage en financement peuvent être déduits au réel dans le cadre d'une exploitation locative en nom propre ou par le bais de sociétés d'exploitation.</p>
  </div>
  <div>
    <h4>Dans le cadre d'une prestation de courtage, si vous ne me trouvez pas de financement, dois-je avancer des frais ?</h4>
    <p>Absolument pas ! Courtage Patrimoine se rémunère uniquement en cas de réussite de votre financement. De plus, la loi MURCEF stipule qu’un courtier ne peut percevoir d'honoraires avant le déblocage des fonds par la banque.</p>
  </div>
  <div>
    <h4>Comment procédez-vous pour la recherche des biens immobiliers ?</h4>
    <p>Chez Courtage Patrimoine, nous sommes en constante observation du marché immobilier. Nous procédons avec une stratégie simple : l'arbitrage. Cela consiste à la prospection de biens sous-évalués par rapport à leur valeur de vente possible. Nous disposons d'outils et d’algorithmes permettant de trouver des biens qui seront rentables dès leur acquisition. </p>
    <p>Nous sommes également présents sur un large réseau de professionnels de l'immobilier qui peuvent nous alerter avant la mise en vente d'un bien avantageux sur le marché. Ces informations réservées aux professionnels et non accessibles aux particuliers sont appelées les opportunités « off-market ».</p>
    <p>Enfin, notre expertise nous permet d’évaluer le potentiel d’un bien et ses plus-values possibles afin de vous offrir des opportunités uniques d’investissement.</p>
  </div>
  <div>
    <h4>Dans quel type de ville proposez-vous d'investir ?</h4>
    <p>Le choix de la localité du bien repose sur plusieurs critères qui sont dépendant de votre situation et des paramètres inhérents à chaque ville. En effet, la volatilité des prix n'est pas la même d’une ville à une autre. Par exemple, une ville où l'appréciation immobilière est faible mais constante ne subira pas les mêmes dépréciations en cas de décroissance du marché qu'une ville où l'appréciation est très forte.</p>
  </div>
  <div>
    <h4>Étant présents sur Bordeaux, pouvez-vous travailler avec des personnes de la France entière ?</h4>
    <p>Tout à fait. Nous sommes une entreprise dynamique et connectée. Nous effectuons des déplacements régulièrement dans le cadre de la prospection immobilière et notre espace client nous permet de travailler à distance sur l'ensemble des prestations. Nous travaillons également en visio-conférence avec nos clients.</p>
  </div>
  <div>
    <h4>Est-il sûr d’investir dans l’immobilier aujourd’hui ? </h4>
    <p>L'immobilier représente la classe d'actif où les investisseurs sont le plus en confiance. Sa matérialisation physique la rend plus compréhensible qu'un titre financier. De plus, se loger reste l'un des 4 besoins fondamentaux dans notre société. C'est donc un placement reconnu au travers des âges. Cependant, comme tout investissement, il comporte un risque de perte en capital lié à la fluctuation des prix de l'immobilier, eux-mêmes corrélés à d'autres facteurs (l'accès aux crédits, l'offre et la demande, etc.). </p>
  </div>
  <div>
    <h4>Qu’est-ce qui différencie votre société de gestion du patrimoine d’une autre sur le marché de l’immobilier ?</h4>
    <p>Nous ne commercialisons pas les biens de société de promotion immobilière ou autres. Notre approche n'est pas de voir si un produit d'autrui vous correspond, mais de partir de votre situation afin de créer un produit adapté à tous vos paramètres financiers. </p>
  </div>
  <div class="last-mobile">
    <h4>Vous êtes plutôt thé ou café ?</h4>
    <p>Plutôt thé. Thé blanc de Pai Mu Tan…</p>
  </div>
</div>
