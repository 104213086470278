<div class="page-wrapper">
<h3 class="logged-title">Nouveau client</h3>
<div class="subcontainer">
  <button class="previousButton" (click)="navigateToClientsList()">Retour</button>
</div>
<h3 class="logged-title">Selectionnez le type de clients</h3>
<div class="subcontainer">
  <button class="userType" *ngFor="let type of userTypes" (click)="selectUserType(type)">{{type.name}}</button>
</div>
<form *ngIf="editedUser.userType.name!=''" (ngSubmit)="createForm.valid && onSubmit()" #createForm="ngForm">
  <section>
    <div class="formSubcontainer">
      <label for="firstName">Prénom</label>
      <input type="text" name="firstName" [(ngModel)]="editedUser.firstName" #firstName="ngModel">
    </div>
    <div class="formSubcontainer">
      <label for="lastName">Nom</label>
      <input type="text" name="lastName" [(ngModel)]="editedUser.lastName" #lastName="ngModel">
    </div>
    <div class="formSubcontainer">
      <label for="companyName">Raison sociale</label>
      <input type="text" name="companyName" [(ngModel)]="editedUser.companyName" #companyName="ngModel">
    </div>
    <div class="formSubcontainer">
      <label for="siretNumber">Numéro siret</label>
      <input type="text" name="siretNumber" [(ngModel)]="editedUser.siretNumber" #siretNumber="ngModel">
    </div>
    <div class="formSubcontainer">
      <label for="email">Email <span class="mandatoryField">*</span></label>
      <input type="email" name="email" [(ngModel)]="editedUser.email" #email="ngModel" required>
    </div>
    <div *ngIf="email.errors?.required && createForm.submitted" class="errorMessage">Une addresse email est requise.</div>
    <div class="formSubcontainer">
      <label for="phoneNumber">Téléphone</label>
      <input type="tel" name="phoneNumber" [(ngModel)]="editedUser.phoneNumber" #phoneNumber="ngModel" minlength="10" maxlength="10">
    </div>
    <div class="errorMessage" *ngIf="phoneNumber.errors?.minlength && createForm.submitted">Le numéro de téléphone doit faire plus de 10 charactères.</div>
    <div class="formSubcontainer">
      <label for="password">Mot de passe <span class="mandatoryField">*</span></label>
      <input type="password" name="password" [(ngModel)]="editedUser.password" #password="ngModel" required minlength="8">
    </div>
    <div class="errorMessage" *ngIf="password.errors?.required && createForm.submitted">Un mot de passe est requis.</div>
    <div class="errorMessage" *ngIf="password.errors?.minlength && createForm.submitted">Le mot de passe doit faire plus de 8 charactères.</div>
    <div class="formSubcontainer">
      <label for="sponsorshipCode">Code partenaire</label>
      <input type="text" name="sponsorshipCode" [(ngModel)]="editedUser.sponsorshipCode" #sponsorshipCode="ngModel">
    </div>
  </section>
  <section>
    <div class="formSubcontainer">
      <label for="street">Rue</label>
      <input type="text" name="street" [(ngModel)]="editedUser.userAddress.streetName" #streetName="ngModel">
    </div>
    <div class="formSubcontainer">
      <label for="zipCode">Code postal</label>
      <input type="text" name="zipCode" [(ngModel)]="editedUser.userAddress.zipCode" #zipCode="ngModel">
    </div>
    <div class="formSubcontainer">
      <label for="city">Ville</label>
      <input type="text" name="city" [(ngModel)]="editedUser.userAddress.cityName" #city="ngModel">
    </div>
  </section>
  <section>
    <div class="formSubcontainer">
      <label for="ownerName">Titulaire du compte</label>
      <input type="text" name="ownerName" [(ngModel)]="editedUser.userRib.ownerName" #ownerName="ngModel">
    </div>
    <div class="formSubcontainer">
      <label for="bankName">Banque</label>
      <input type="text" name="bankName" [(ngModel)]="editedUser.userRib.bankName" #bankName="ngModel">
    </div>
    <div class="formSubcontainer">
      <label for="ibanNumber">Iban</label>
      <input type="text" name="ibanNumber" [(ngModel)]="editedUser.userRib.ibanNumber" #ibanNumber="ngModel">
    </div>
    <div class="formSubcontainer">
      <label for="bicCode">Code BIC</label>
      <input type="text" name="bicCode" [(ngModel)]="editedUser.userRib.bicCode" #bicCode="ngModel">
    </div>
  </section>

  <div class="subcontainer">
    <button class="principal" type="submit" [disabled]="!createForm.valid">Valider</button>
    <button class="redButton" type="reset">Annuler</button>
  </div>
</form>
</div>
