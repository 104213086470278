<div class="page-wrapper">
  <div class="buttonContainer">
    <button class="previousButton" routerLink="/settings">Retour</button>
  </div>
  <h3 class="logged-title">Types de clients</h3>
  <div class="buttonContainer">
    <button (click)="toggleDisplayForm()">Nouveau</button>
  </div>
<table>
  <tr>
    <th class="id">N° type<hr></th>
    <th class="userTypeName">Nom<hr></th>
    <th class="usersNumber">Clients<hr></th>
    <th class="deleteType">Editer<hr></th>
    <th class="deleteType">Supprimer<hr></th>
  </tr>
  <tbody>
    <tr *ngFor="let type of usersByUserType; let i = index">
      <td class="id">Type de clients {{i+1}}</td>
      <td class="userTypeName">{{type.name}}</td>
      <td class="usersNumber">{{type.users}}</td>
      <td class="deleteType"><img (click)="editUserType(type)" src="/assets/img/edit.svg" alt=""></td>
      <td class="deleteType"><img (click)="deleteUserType(type)" src="/assets/img/trash.svg" alt=""></td>
    </tr>
  </tbody>
</table>
<form *ngIf="displayForm" (ngSubmit)="userTypeForm.valid && onSubmit()" #userTypeForm="ngForm">
  <div class="formSubcontainer">
    <label for="name">Nom<span class="mandatoryField">*</span></label>
    <input type="text" name="name" placeholder="Particulier" [(ngModel)]="userType.name" #userTypeName="ngModel" required>
  </div>
  <div class="errorMessage" *ngIf="userTypeForm.submitted && userTypeName.errors?.required">Un nom est requis pour créer un type d'utilisateur.</div>
  <div class="buttonContainer">
    <button type="submit">Valider</button>
    <button type="reset" class="redButton" (click)="toggleDisplayForm()">Annuler</button>
  </div>
</form>
</div>

